import React from "react";
import { graphql } from "gatsby";

import LandingPage from "@/page-components/landing";
import GraphQLQuery from "@/types";
import { SEO } from "@/layout/SEO";

export interface FormTemplateProps {
  data: GraphQLQuery;
  location: {
    pathname: string;
  };
}

const FormTemplate = ({ location, data }: FormTemplateProps) => {
  return (
    <LandingPage
      {...data.markdownRemark.frontmatter}
      html={data.markdownRemark.html}
      location={location}
      tableOfContents={data.markdownRemark.tableOfContents}
    />
  );
};

export default FormTemplate;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      tableOfContents
      frontmatter {
        ...SeoFields
        minimalNavigation
        productPageHeader
        useScroll
        customFooterCta {
          primary
          secondary
          primaryCta {
            text
            link
            type
          }
          secondaryCta {
            text
            link
            type
          }
        }
        sections {
          title
          placement
          component
          properties {
            # testimonials
            background
            color
            ids
          }
          callToActions {
            text
            link
            behavior
            type
          }
          markdown
          media {
            appFrame
            alternateText
            caption
            video
            vimeoId
            width
            screenshot {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: NONE
                  quality: 85
                  width: 650
                )
              }
            }
          }
          type
          gridItems {
            markdown
            appFrame
            alternateText
            gridImage {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: NONE
                  quality: 85
                  width: 650
                )
              }
            }
          }
          features {
            icon
            markdown
          }
        }
        form {
          headline
          name
          text
          theme
          checklistSection
          testimonialSection
          markdownSection
          imageSection {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: NONE
                quality: 85
                width: 550
              )
            }
          }
          logoSection {
            title
            logos {
              title
              image {
                publicURL
              }
            }
          }
          submitAreaMarkdown
          submit {
            text
            loadingText
          }
          fields {
            name
            label
            type
            validation
            validationMatch
            defaultValue
            autoComplete
            identifier
            queryParameter
            hideFromSegment
          }
          defaultParams {
            key
            value
          }
          confirmation {
            redirect {
              route
              type
            }
            primary
            secondary
            confirmationImage {
              childImageSharp {
                gatsbyImageData(
                  layout: FIXED
                  placeholder: NONE
                  quality: 85
                  width: 200
                )
              }
            }
          }
          subdomain
          route
          errorMessage
        }
      }
    }
  }
`;

export const Head = ({ location, data, params, pageContext }) => {
  const {
    author,
    description,
    metaDescription,
    robots,
    schemaOrg,
    seoImage,
    title,
    titleTag,
  } = data.markdownRemark.frontmatter;

  return (
    <SEO
      author={author}
      description={description}
      metaDescription={metaDescription}
      pathname={location.pathname}
      robots={robots}
      schemaOrg={schemaOrg}
      seoImage={seoImage}
      title={title}
      titleTag={titleTag}
    />
  );
};
